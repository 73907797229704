// keyframe
@keyframes drawqdh {
  25%{stroke-opacity: 1; fill-opacity: 0; }
  40%{stroke-dashoffset:0; stroke-opacity: 1; fill-opacity: 0; }
  40%{stroke-dashoffset:0; stroke-opacity: 1; fill-opacity: 0; }
  90%, 100%{stroke-dashoffset:0; stroke-opacity: 0; fill-opacity: 1; }
}

@keyframes rotate {
	0%{transform:rotate(0deg);}
	100%{transform:rotate(360deg);}
}

@keyframes circlered{
	0%{
		opacity:0;
		transform:scale(0.4);		
	}
	100%{
		opacity:1;
		transform:scale(1);
	}
}

// NEW CSS 
* {
	box-sizing: border-box;
	&:after, &:before {
		box-sizing: border-box;
	}
}
.wrapper--home {
	.anchor-our-work {
		margin-top: 30px;
		height: 30px;
	}
	.block {
		display: block !important;
	}
	.btn--main {
		letter-spacing: 0.93px;
		padding: 0;
		width: 120px;
		height: 40px;
		border-radius: 20px;
	}
	.skat {
		overflow: hidden;
	}
	.client-logo {
		cursor: pointer;
	}
	.client-logo-mobile {
		display: none;
	}
	.contact__form {
		.section__title {
			line-height: 46px;
			margin-top: 165px;
			letter-spacing: 0;
			margin-bottom: 87px;
		}
	}

	.footer-copyright-bottom {
		font-size: 14px;
		color: #000;
		letter-spacing: 0.17px;
		line-height: 32px;
		margin: 108px 0 61px 0;
	}

	.greeting-text {
		margin-top: 0;
		font-size: 60px;
		letter-spacing: 2px;
		line-height: 76px;
		margin-bottom: 74px;
	}

	.grid-about {
		&:after, &:before {
			display: table;
			content: " ";
			zoom: 1;
		}
		&:after {
			clear: both;
		}
	}

	.grid-about__left, .grid-about__center, .grid-about__right {
		position: relative;
		min-height: 1px;
		float: left;
	}

	.grid-about__left, .grid-about__right {
		width: 40%;
	}

	.grid-about__center {
		width: 20%;
		margin-top: 18%;
	}

	.grid-about__center-mobile {
		display: none;
	}

	#home {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.introduce-text {
		font-size: 24px;
		letter-spacing: 0.33px;
		line-height: 40px;
		margin-bottom: 161px;
		width: 73%;
		margin: 0 auto;
	}

	.menu-work {
		list-style-type: none;
		padding: 0;
		margin: 0 0 42px 0;
		li {
			display: inline-block;
			margin-right: 83.7px;
			line-height: 14px;
			margin-left: -4px;
			padding-bottom: 10.3px;
			border-bottom: 1px solid transparent;
			&:last-child {
				margin-right: 0;
			}
			&.active {
				border-bottom: 1px solid #000;
				> a {
					&:hover {
						color: #000;
					}
				}
			}
			> a {
				font-size: 14px;
				letter-spacing: 1.2px;
				line-height: 12.79px;
				font-family: inherit;
				color: #000;
				&:hover {
					color: #F1494c;
				}
			}
		}
	}

	.section-primary {
		position: relative;
		background-color: #fff;
		color: #000;
		justify-content: flex-start;
		flex-direction: column;
	}

	.section-primary__caption {
		position: static;
		width: 1024px;
		margin: 0 auto;
		bottom: 42px;
		text-align: center;
		left: 0;
		right: 0;
	}

	.text-center {
		text-align: center;
	}

	.thumbnail-work-group {
		&:after, &:before {
			content: " ";
			display: table;
			zoom: 1;
		}
		&:after {
			clear: both;
		}
	}

	.thumbnail-work-group__item {
		display: block;
		cursor: pointer;
		float: left;
		min-height: 1px;
		position: relative;
		width: 33.33333333%;
		&:nth-child(even) {
			.thumbnail-work-group__img {
				background-color: #CECECE;
			}
		}
		&:nth-child(odd) {
			.thumbnail-work-group__img {
				background-color: #EDECEC; 
			}
		}
		&:hover {
			.thumbnail-work-group__img {
				> img {
					transform: scale(1.1);
				}
				.cover {
					transform: scale(1.1);
				}
			}
		}
	}

	.thumbnail-work-group__img {
		align-items: center;
		justify-content: center;
		display: flex;
		height: 360px;
		overflow: hidden;
		padding: 0 15px;
		&.cover {
			transition: transform 400ms ease-in-out;
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
		}
		&.end {
			align-items: flex-end;
		}
		> img {
			transition: transform 400ms ease-in-out;
		}
		.logo-davabar {
			margin-bottom: -70px;
		}
	}

	.thumbnail-work-group__caption {
		box-sizing: border-box;
		color: #000;
		text-align: center;
		height: 112px;
		padding: 20px 0 34px 0;
	}

	.thumbnail-work-group__category {
		font-family: "montserrat.light", sans-serif;
		text-transform: uppercase;
		font-size: 12px;
		letter-spacing: 1.02px;
		line-height: 30.1px;
	}
	
	.thumbnail-work-group__project {
		font-family: "montserrat.regular", sans-serif;
		font-size: 24px;
		letter-spacing: 0;
		color: #000;
	}
	.work-heading {
		color: #000;
		margin-bottom: 77.34px;
		font-size: 36px;
		line-height: 49.66px;
		letter-spacing: 0;
	}
	.section-works {
		.section__wrapper {
			padding: 207px 0 167px 0;
		}
	}
	.section-about__heading {
		font-size: 28px;
		letter-spacing: 0;
		line-height: 44px;
		color: #fff !important;
		margin-bottom: 39px;
	}
	.section-about__info, .section-about__desc {
		min-height: 0 !important;
	}
	.section-about__info {
		margin: 0;
		margin-top: 32.51px;
		margin-bottom: 56.82px;
		color: #F1494C;
		font-size: 13.54px;
		letter-spacing: 1.16px;
		line-height: 27.09px;
	}
	.section-about__desc {
		letter-spacing: 0.27px;
		line-height: 34px;
		color: #D8D8D8;
		font-size: 20px;
	}
	#contact {
		.section__wrapper {
			padding: 0;
		}
	}
	#works {
		.section__title {
			margin-bottom: 143px;
			letter-spacing: 0;
		}
	}
}

@media (max-width: 1199px) {
	.wrapper--home {
		.thumbnail-work-group__img {
			&.end {
				align-items: center;
			}
			.logo-davabar {
				margin-bottom: 0;
			}
		}
	}
}

@media (max-width: 1024px) {
	.wrapper--home {
		.section-primary__caption {
			width: 100%;
		}
	}
	.home { 
		#about {
			.service__desc {
				p {
					text-align: center;
				}
			}
		}
	}
}

@media (max-width: 991px) {
	.wrapper--home {
		.grid-about {
			.grid-about__left, .grid-about__right {
				width: 100%;
			}
			.grid-about__center {
				display: none;
			}
		}
		.introduce-text {
			width: 100%;
		}
		.section-about__desc {
			width: 70%;
			margin: 0 auto;
		}
		.section-about__heading {
			margin-bottom: 20px;
		}
		.section-about__info {
			margin: 20px 0;
		}
		.thumbnail-work-group__item {
			width: 50%;
		}
		.thumbnail-work-group__img {
			.logo-davabar {
				width: 368px;
			}
		}
		.grid-about__center-mobile {
			position: relative;
			width: 100%;
			display: block;
			float: left;
			text-align: center;
			padding: 95px 0 81px 0;
		}
	}
}

@media (max-width: 768px) {
	.wrapper--home {
		.client-logo {
			display: none;
		}
		.client-logo-mobile {
			display: block;
		}
		.contact__form {
			.section__title {
				line-height: 34px;
				font-size: 26px;
				letter-spacing: 0;
				margin-top: 68px;
				margin-bottom: 39px;
			}
			.form__wrapper {
				&.center {
					padding-top: 28px;
				}
			}
			input[type="text"] {
				padding: 0;
				height: 31px;
				padding-left: 10px;
			}
			textarea {
				height: 174px;
			}
		}
		.footer-copyright-bottom {
			padding: 0 20px;
			margin: 60px 0;
			font-size: 12px;
			line-height: 20px;
			letter-spacing: 0;
		}
		.greeting-text {
			margin-top: 143px;
			margin-bottom: 53px;
			font-size: 50px;
			line-height: 76px;
			letter-spacing: 1.67px;
		}
		.hidden-mobile {
			display: none;
		}
		.introduce-text {
			font-size: 18px;
			line-height: 30px;
			margin-bottom: 79px;
		}
		.menu-work {
			margin-bottom: 22px;
			li {
				margin-right: 48.66px;
				> a {
					font-size: 12px;
				}
			}
		}
		.thumbnail-work-group__item {
			width: 100%;
			margin-bottom: 15px;
		}
		.thumbnail-work-group__img {
			height: 309px;
			> .logo-kububeach {
				max-width: 45%;
			}
			> .logo-kampoengbali {
				max-width: 60%;
			}
			> .logo-flashads {
				max-width: 90%;
			}
			.logo-davabar {
				//width: 320px;
				width: 100%;
			}
		}
		.section-about__desc {
			width: 100%;
		}
		.section-about__heading {
			font-size: 28px;
			line-height: 44px;
		}
		.section-primary__caption {
			position: static;
			margin-top: 20px;
		}
		.work-heading {
			font-size: 26px;
			margin-bottom: 15px;
		}
		.section-works {
			.section__wrapper {
				padding: 0;
			}
		}
		.section-about__desc {
			font-size: 18px;
			line-height: 30px;
			letter-spacing: 0;
			color: #D8D8D8;
		}
		.section-primary__caption {
			margin-top: 0;
		}
		.work-heading {
			font-size: 30px;
			margin: 0;
			line-height: 49.66px;
			margin-bottom: 45.66px;
		}
		#about {
			.container__wrapper {
				margin-top: 91px;
				margin-bottom: 102px;
			}
			.service__desc {
				h3 {
					margin: 0 0 39px 0;
				}
			}
		}
		#contact {
			.section__wrapper {
				padding: 0;
			}
		}
	}
	.home {
		#home {
			min-height: 0;
		}
		#works {
			.section__title {
				font-size: 30px;
				line-height: 56px;
				margin-top: 70px;
				margin-bottom: 78px;
			}
		}
	}
}

@media (max-width: 380px) {
	.wrapper--home {
		.logo-kampoengbali {
			width: 200px;
		}
	}
}

@media (max-width: 365px) {
	.wrapper--home {
		.thumbnail-work-group__img {
			.logo-davabar {
				width: 260px;
			}
		}
	}
}

@media (min-width: 769px) and (max-height: 600px) {
	.wrapper--home {
		.greeting-text {
			margin-top: 140px;
		}
		.introduce-text {
			margin-bottom: 150px;
		}
		#home {
			min-height: 0;
		}
		.menu-work {
			margin-bottom: 30px;
		}
		.section-primary__caption {
			position: static;
		}
		.work-heading {
			/* margin-top: 150px; */
			margin-top: 0;
		}
	}
}

// end new

.home{
	@extend .noflicker;
	.section__wrapper{
		h2, h3{
			color:#000;
		}
	}

	#home{
		.svg--logo-qdh{
			@extend .noflicker;
			margin:10px 0;			
			opacity:0;
			visibility: hidden;
			transition: 1s all;
			.qdhqeon,
			.qdhdigital,
			.qdhhouse{ 
				opacity:0;
				visibility: hidden;
				fill-opacity: 0; 
			}		
			&.animate{
				opacity:1;
				visibility: visible;
				.qdhlogo{
					stroke-dashoffset:600;
					stroke-dasharray:600;
					opacity: 1;
					visibility: visible;		
					fill-opacity: 0;
					animation: drawqdh 3s cubic-bezier(0, 0, 1, 0) forwards;
					@extend .noflicker;
				}
				.qdhqeon{
					opacity: 1;
					visibility: visible;		
					fill-opacity: 1;
					transition: 1.5s cubic-bezier(0, 0, 1,-0.04); 
					@extend .noflicker;
				}
				.qdhdigital{
					opacity: 1;
					visibility: visible;		
					fill-opacity: 1;
					transition: 1.5s cubic-bezier(0, 0, 1,-0.04) 0.5s;
					@extend .noflicker;
				}
				.qdhhouse{
					opacity: 1;
					visibility: visible;		
					fill-opacity: 1;
					transition: 1.5s cubic-bezier(0, 0, 1,-0.04) 1s;
					@extend .noflicker;
				}
			}
		}
		.hr--inner100{
			margin: 60px auto 45px auto;
		}
	}

	// temp
	#temp{
		display: block;
		background:#fbfbfb;
		min-height: auto;
		.bg__wrapper{
			width:100%;
			height:572px;
			overflow:hidden;
			.bg{
				height:200%;
				top:-100%;
				background-repeat:repeat;
			}			
		}
		.content--grey{
			p{ font-size: 14px;}
		}
	}

	// about
	#about{
		@extend #temp;
		background: #252525;
		min-height: 100vh;
		display: flex;

		h3{
			color:#F1494c;
		}
		.content--grey{
			position: relative;
			z-index: 5;
			p{ text-align: left; font-size: 14px;}
		}		
		.icoservice{
			position: relative;
			min-height: 200px;
			height: 200px;
			width: 200px;
			margin: auto;
		}
		.ico--lamp{
			position: absolute;
			top:0; left:120%; right: initial;
			height: 200px;
			width: 200px;
			border-radius: 50%;
			color:#fff;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			&:before{
				z-index: 1;
				opacity: 0;
				font-size: 90px;
				color: #252525;
			}
			&:after{
				content:"";
				position: absolute;
				top:0; left:0; right:0; bottom:0;			    				
				height: 100px;
				width: 100px;
				margin:auto;				
				border-radius: 50%;
				z-index: 0;
				opacity:0;
			}		
			&.animate{			
				top:0; left:0px;
				height: 200px;
				width: 200px;
				margin:0 auto;
				border-radius: 50%;
				transition:1.3s all 0.7s;
				&:before{
					opacity: 1;			
					font-size: 130px;
					transition:0.6s all 1.5s;
				}
				&:after{
					content:"";		
					height: 200px;
					width: 200px;
					background: #fff;
					opacity:1;
					transition:0.6s all;
				}				
			}
			&.normal{			
				top:0; left:0px;
				height: 200px;
				width: 200px;
				margin:0 auto;
				border-radius: 50%;
				&:before{
					opacity: 1;			
					font-size: 130px;
				}
				&:after{
					content:"";		
					height: 190px;
					width: 190px;
					background: #fff;
					opacity:1;
				}				
			}
		}
		.ico--phone{
			@extend .ico--lamp;
    		top:0; left:-120%;
    		right:inherit;
    		&.animate{
    			left:0;
    		}
    		&.normal{
    			left:0;
    		}
    	}
    	.ico--plus{
    		position: absolute;
    		height: 40px;
    		width: 40px;
    		top:0; 
    		bottom:0; 
    		margin:auto;
    		left: 0;
    		right: 0;
			&:before{
				content: '';
				position: absolute;
				top:48%; right:initial; left:auto;
				margin:auto;
				width: 0px;
				height: 3px;
				background: #FF0000;
			}
			&:after{
				content: '';
				position: absolute;
				top: auto; left:48%; right:initial;
				width: 3px;
				height: 0px;
				background: #FF0000;
			}
			&.animate{
				&:before{
					width: 40px;
					transition:0.6s all 1.5s;
				}
				&:after{
					height: 40px;
					transition:0.6s all 1.7s;
				}
			}
    	}
		.service__desc{
			position: relative;
			top: 30px;
			visibility: hidden;
			opacity: 0;
			&.animate{
				top: 0px;
				visibility: visible;
				opacity: 1;
				transition:1s all 1.6s;
			}
			&.normal{
				top: 0px;
				visibility: visible;
				opacity: 1;
			}
			p{
				min-height:140px;
			}
		}


		.column__right{
			p{
				font-size: 11px;
				line-height: 21px;
			}
		}
		.column__left{
			p{
				width: 85%;
			}
		}
		ul.disc{
			list-style-type: none;
			padding: 0;
			li{
				color: #F1494c;
				font-size: 13px;
				letter-spacing: 1.5px;
			}
		}
	}

	#works{
		@extend #temp;
		
		.client__list__wrap{
			display: flex;
			flex-wrap: wrap;
		}
		.client__list_item{
			width: 25%;
			height: 110px;
			vertical-align: middle;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 60px;
		}
		.works__filter{
			position: relative;
			padding: 0;
			li{
				display: inline-block;
				// width:130px;
				padding: 5px 50px;
				text-align: center;
				position:relative;
				margin-right: 15px;
				&:first-child{
					padding-left:0;
				}
				&:last-child{
					padding-right:0;
				}
				a{
					color: #000;
					position: relative;
					&:before{
						content: "";
						position: absolute;
						width: 100%;
						height: 2px;
						bottom: -2px;
						left: 0;
						background-color: #3C3C3B;
						visibility: hidden;
						width:0;
						transition: all 0.3s ease-in-out;
					}
					&:hover{
						// color: darken(#CECECE, 5%);
						&:before{
							visibility: visible;
							width: 100%;
						}
					}
				}
				&:last-child{
					margin-right: 0;
				}
				&.active{
					a{
						color: darken(#CECECE, 5%);
						pointer-events: none;
					}
				}
			}
		}
	}

	// contact
	#contact{
		@extend #temp;
		background-color: #fbfbfb;	
		.contact__wrapper{
			width:100%;
			display: flex;
			justify-content:center;
		}
		#map{
			width:100%;
			height:450px;
		}
		.hr--bold{
			width:0;
			&.animate{
				width: 250px;
			}
		}
		.text{
			opacity: 0;
			&.animate{ opacity:1; }
		}
		small{
			color:#464646;
		}
		
		.contact__wrap{
			position: relative;
		}
		.inquiry-success{
			.contact__success{
				opacity: 1;
				visibility: visible;
			}
		}
		.contact__success{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: #fbfbfb;
			transition: all .5s ease-in;
			opacity: 0;
			visibility: hidden;
		}
	}
	#projects{
		// background:#f8f5ed;
		.section__wrapper{
			.content--grey{
				width: 85%;
				margin: 30px auto 20px auto;
				
			}
		}
	}
}
#section-socmed{color:#CECECE;margin: 15px 0;}

// responsive
@include break (1360){}
@include break (1024){
	.home{
		#home{
			.container{
				width:800px;
			}
		}
		#contact{
			.container1200{
				width: 800px;
			}
		}
		#works{
			.container1200{
				width:100%;
			}
		}
		#clients{
			.float-left, .float-right{
				float: none;
			}
			.column33{
				margin: 0px auto;
				width: 50%;
				text-align: center;
			}
			.column66 {
				width: 90%;
				margin: 30px auto 0 auto;
			}
		}
		#about{
			.column__left, .column__right{
				float: none !important;
			}
			.column__left{
				margin: 0 auto;
				text-align: center;
				width: 80%;
				p{
					text-align: center;
					width:100%;
				}
			}
			.column__right{
				width: 80%;
				margin: 30px auto 0 auto;
			}
			.icoservice{
				min-height: 190px;
				height: 190px;
			}
			.ico--lamp{
				// left: 100%;
				height: 190px !important;
				width: 190px !important;
				&.animate{
					// right: 0;
					&:before{
						font-size: 100px;
					}
					&:after{
						height: 180px;
						width: 180px;
					}
				}
				&.normal{
					// right: 0;
					&:before{
						font-size: 100px;
					}
					&:after{
						height: 170px;
						width: 170px;
					}
				}
			}
			.service__desc{
				h3{
					width: 100% !important;
					text-align: center;
				}
				p{
					text-align: center;
				}
			}
		}
	}	
}
@include break (980){
	.home{
		#works{
			.works__filter{
				text-align: center;
			}
		}
	}
}
@include break (800){
	.home{
		#home{
			.container{
				width:90%;
			}
		}
		#contact{
			.container1200{
				width: 600px;
			}
		}
		#works{
			.client__list_item{
				width: 33.3%;
			}
		}
		#about{
			.container800{
				width:90%;
			}
			.icoservice{
				min-height: 170px;
				height: 170px;
				width: 170px;
			}
			.ico--lamp{
				height: 170px !important;
				width: 170px !important;
				&.normal{
					// right: 0;
					&:before{
						font-size: 100px;
					}
					&:after{
						height: 170px;
						width: 170px;
					}
				}
			}
			.ico--plus{
				display: none;
			}
			.service__desc{
				ul{
					li{
						font-size: 11px;
					}
				}
			}
			.column40{width:45%;}
		}
	}
}
@include break (768){
	.home{
		h2{
			font-size: 22px;
		}
		h3{font-size: 20px;}
		#home{
			.container768{width:100%}
			.slogan__wrapper{
				.slogan{
					span{
						font-size: 38px;
					}
				}
				p{
					font-size: 14px;
					padding: 0 10px;
				}
			}
		}
		#contact{
			.container1200{
				width: 90%;
			}
		}
	}
}
@include break (600){
	.home{
		#clients{
			.column33{
				width: 70%;
			}
			.client__list_item {
				width: 33.33%;
			}
		}
		#about{
			min-height: auto;;
			.icoservice{
				min-height: 150px;
				height: 150px;
				width: 150px;
			}
			.ico--lamp{
				left: 100%;
				height: 150px !important;
				width: 150px !important;
				&.normal{
					right: 0;
					&:before{
						font-size: 80px;
					}
					&:after{
						height: 150px;
						width: 150px;
					}
				}
			}
			.float-left, .float-right{
				float: none;
			}
			.column40{
				width: inherit;
				margin-bottom: 50px;
				.normal{
					p{
						br{
							display: none;
						}
					}
				}
				.service__desc{
					p{
						min-height:auto;
					}
				}
			}
		}
		#works{
			.client__list_item{
				width: 50%;
			}
			.works__filter{
				li{
					width: 120px;
					margin: 7px;
				}
			}
		}
	}
}
@include break (480){
	.home{
		#home{
			align-items: flex-start;
			.container{padding-top: 60px;}
			.slogan__wrapper{
				.slogan{
					span{
						font-size: 20px;
					}
				}
				p{
					font-size: 12px;
				}
			}
		}
		#contact{
			.column40{
				width: 80%;
			}
		}
		#clients{
			.client__list_item {
				width: 50%;
			}
		}
		#about{
			.column__right{
				.column40{
					width: 100%;
					float: none;
				}
			}
		}
	}	
}


