*,
*:before,
*:after {
  	box-sizing: border-box;
}
a, a:active, a:focus, input, p, article {outline: none;}
iframe{border: none;}
body {
  // background: #17141a;
  margin:0;
  overflow-x: hidden;
}
html,body{
	min-height: 100%;
    padding: 0;
}
img {	
	max-width: 100%;
	height:auto;
	outline: none;
}
.noflicker{ 
	backface-visibility:hidden; 
	transform:translateZ(0) translate3d(0,0,0);
	transform-style: preserve-3d;
}
.left {
	text-align: left;
	justify-content: flex-start;
}
.right {
	text-align: right;
	justify-content: flex-end;
}
.center {
	text-align: center;
	justify-content: center;
	margin: 0 auto;
}
.text-danger {
	color: #cc3f44 !important;
}
.text-warning {
	color: #ffba00 !important;
}
.text-success {
	color: #8dc63f !important;
}
.text-info {
	color: #40bbea !important;
}
.margin0{
	margin:0 !important;
}
.padding0 {
	padding: 0 !important;
}
.ultralight{
	font-family: 'montserrat.ultralight';
}
.light{
	font-family: 'montserrat.light', sans-serif;
}
.semibold{
	font-family: 'montserrat.semibold', sans-serif;
}
.bold{
	font-family: 'montserrat.bold', sans-serif;
}
.spacing0{ letter-spacing:0 !important; }
.flex {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
}
.clearfix{
	clear: both;
	&:after{
		content: '';
		clear: both;
		display: table;
		line-height: 0;
	}
}
.float-right{
	float: right;
}
.float-left{
	float: left;
}
.space__header{
	padding-top: 80px;
}
.container{
	position:relative;
	width:1000px;
	max-width:100%;
	margin:0 auto;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	&__wrapper{
		max-width: 100%;
		margin: 20px auto;
		padding:0px 0px;
	}
	&full {
		width: 100%;
		// max-width: 1200px;
		margin: auto;
	}
	&1200{
		width: 1200px;
	    max-width: 100%;
	}
	&1024{
		margin: 0 auto;
		width: 1024px;
		max-width: 100%;
	}
	&800{
		width: 800px;
	    max-width: 100%;
	}
	&768{
		width: 768px;
	    max-width: 100%;	    
	}
	&600{
		width: 600px;
	    max-width: 100%;	    
	}
	&580{
		width: 580px;
	    max-width: 100%;	    
	}
	&400{
		width: 400px;
	    max-width: 100%;
	}
}
.column{	
	&__wrapper{
		padding:20px;
	}
	&25{ width:25%;	}
	&30{ width:30%;	}
	&33{ width:33.33%; }
	&40{ width:40%; }
	&45{ width:45%; }
	&50{ width:50%; }
	&55{ width:55%; }
	&60{ width:60%; }
	&66{ width:66%; }
	&70{ width:70%; }
	&80{ width:80%; }
	&90{ width:90%; }
	&full{ width:100%; }
}
.over{
	overflow:hidden;
	&-x{
		overflow-x:hidden;
	}
	&-y{
		overflow-x: hidden; 
		overflow-y: auto;
	}
}
.bg{
	height: 100%;
	position: absolute;
	top: 0;
	z-index:0;
	width:100%;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	@extend .noflicker;
	&__wrapper{
		position:relative;
	}
	&--grey{
		background-color:#edecec;
	}
	&--black{
		background-color:#252525;	
	}
	&--white{
		background-color:#FFFFFF;	
	}
}
.content{
	&--white{
		color:white;
	}
	&--grey{
		color:#464646;
	}
}
.section{
	position:relative;
	overflow:hidden;
	width:100%;
	min-height:100vh;
	display:flex;
	justify-content:center;
	align-items:center;
	&__wrapper{
		padding:50px 0;
	}
	&__title{
		margin:0 auto 50px auto;
	}
	&.height-auto{
		min-height:auto;
	}
}
#section1,
#home{ 
	@extend .noflicker; 
	min-height:100vh;	
	.hr--bolder{
		margin:30px auto 20px auto;
		opacity: 0;
		&.animate{ animation:hrscalelr 4s cubic-bezier(0.9, 0, 0.1, 0.87) 1.5s forwards; } 
	}
	.arrow-to__redbg,
	.arrow-to__section2{ 
		position: static !important;
	}
	.ico--arrowdown{
		@extend .noflicker;
		cursor:pointer;
		position:absolute;
		bottom:70px; left:0; right:0;
		margin:auto;
		background:url('/images/ico-arrowdown.png') no-repeat;
		width:24px;
		height:14px;
		animation: bounce 2s infinite;
	}
	.textmain{
		position: absolute;
		top:0; left:0;
		width:100%;
		height:100%;
		display: flex;
		justify-content:center;
		align-items:center;  
	    opacity: 1;
		visibility: visible;
		transition: 2.3s all;
	    &.active{
	    	// bottom: 160px;					
			opacity: 0;
			visibility: hidden;
			transition: 1s all;
		}
		h1{
			font-family: 'montserrat.ultralight', sans-serif;
		    font-size: 55px;
		    line-height: 65px;		    
		    font-weight: normal;
		    margin: 0 auto;
		    text-transform: uppercase;
	    	letter-spacing: 2px;
	    	position: absolute;
			bottom: 170px; left: 0; right: 0;		
		    width: 900px;
		    max-width: 100%;
		    text-align: center;
		}
	}
	.bg{		
		.bg-grad{
			@extend .noflicker;
			transition: 1.5s all;
			position:absolute;
			top:0;
			width:100vw;
			height:100vh;
/* 			&-red{
	            background: linear-gradient(360deg, rgba(169, 0, 0, 0.5) 50%, transparent 88%);
	            opacity:0;
				visibility: hidden;				
			} */
		}			
/* 		&.active{
			.bg-grad{
				&-black{
				}
				&-red{
					opacity:1;
					visibility:visible;
				}					
			}
		} */
	}
	.section1__content{
		@extend .noflicker;
		opacity: 0;
		visibility: hidden;
		transition: 1.5s all;
		&.active{
			opacity:1;
			visibility: visible;
		}
	}
	.our-roles{
		margin:60px auto;
	}
}
.bg-overlay{
	transition: 1.5s all;
	position:absolute;
	top:0;
	width:100vw;
	height:100%;
	background-color: rgba(0,0,0,0.7);
}
.socmed__link{
	.socmed__link__a{
		margin: 0 10px;
		color: #CECECE;
		font-size: 24px;
		transition: all .3s;
		&:hover{
			color: #747474;
		}
	}
}

.icon-close-menu {
	position: absolute;
    top: 30px;
    right: 30px;
}

// Responsive
// height
@media (max-height: 700px) {
	#section1,
	#home{
		.textmain{
			bottom:90px;
		}
		.ico--arrowdown{
			bottom:30px;
		}
		.section1__content{
			p{ margin: 40px 0; }
			br{ display: none; }
			.button--main{ margin: 20px 0 0 0; }
		}
	}
}

// width
@include break (1360){
	#section1,
	#home{
		.bg{ background-image: url('/images/kampoengbali/bg-main1360.jpg'); }
	}
}

@include break (1024) {
	.container {
		&1024 {
			padding: 0 20px;
			width: 100%;
		}
	}
}

@include break (1024){
	#section1,
	#home{
		height:initial;
		.bg{ background-image: url('/images/kampoengbali/bg-main1024.jpg'); }
		.textmain{
			h1{
				font-size: 45px;
				line-height: 55px;
				bottom: 140px;
			}
		}
	}
	.section{
		&__wrapper{
			padding:40px 0;
		}
	}
}
@include break (980){
	#section1,
	#home{
		.textmain{ display:none; }
		.section1__content{ opacity: 1; visibility: visible; }
		.bg{			
			.bg-grad{
				transition: none;				
				&-black{ opacity:0; visibility:hidden; }
				&-red{ opacity:1; visibility: visible; }
			}
		}
	}
}
@include break (768){
	body{
		overflow-y:auto !important;
	}
	.column{
		&33{
			float:left;
			width:100%;		
		}
		&25{
			float:left;
			width:50%;		
		}
		&50{
			float:left;
			width:100%;
		}
	}
	.space__header{padding-top: 60px;}
	#section1,
	#home{
		.bg{ background:transparent; }
		.section1__content{
			p{ padding:0 10px; }
			.list__red li { 
				font-size:12px;
				width: 100%;
			    margin: 2px 0;
			    padding: 0;
			    border:0;
			}
		}
	}
	#section-project-other {
		min-height: 0 !important;
	}
}
@include break (600){
	.our-roles{ display: none; }
}

@media (max-width: 767px) {
	#section-project-other {
		display: block;
	}
}

