#body{
  .bx-wrapper {
    box-shadow: none;
    border: none;
    background: none;
    margin: 0 auto;
    .bx-viewsport{

    }
    .bx-controls{
      .bx-pager{
        &-item{

        }
        &-link{
          width: 8px;
          height: 8px;
          margin: 0 5px;
          border-radius: 50%;
          background:#646464;
          &.active{
            background:#ff0000;
          }
        }
      }
    }
  }
  .bxslider{
    li{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
