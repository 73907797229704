.project__other{
	&__ul{

	}
	&__li{
		position:relative;
		width:25%;
		display: flex;
		&.coming-soon{
			.project__other__a{
				// pointer-events: none;
				// &:hover{
				// 	.project__other__img{ transform: scale(1); }
				// }
			}
			.project__other__desc{
				// top:0; 
				bottom:0;
				padding: 28px 0 28px 20px;
				display: flex;
				align-items: flex-start;
				flex-flow:column wrap;
			}
			.project__other__h2,
			.project__other__h4{
				opacity: 1;
				font-size: 20px;
			}
			.project__other__desc{
				background: rgba(0, 0, 0, 0.6);
			}
		}
	}
	&__img{
		transform: scale(1.1);
		transition: 0.4s all;
	}
	&__desc{
		visibility: hidden;
		opacity: 0;
		position:absolute;
		// top:0; 
		text-align: left;
		bottom:0; right:0; left:0;
		margin:auto;
		background:rgba(0,0,0,0.6);
		padding: 15px 0 15px 20px;
		transition: 0.4s all;
	}
	&__h2{
		opacity: 1;
		font-size: 22px;
		letter-spacing: 1px;
		transition: 0.4s all;
	}
	&__h4{
		opacity: 1;
		transition: 0.4s all;
		font-size: 13px;
		letter-spacing: 1px;
		line-height: 25px;
	}
	.hr--inner45{
		margin:5px auto;
		transition: 0.4s all;
		opacity:0;
	}
	&__a{
		transition: 0.4s all;	
		overflow: hidden;	
		&:hover{
			.project__other__desc{
				visibility: visible;
				opacity: 1;
			}
			.project__other__img{ transform: scale(1.2); }
			.project__other__h2,
			.project__other__h4{
				opacity: 1;
			}
			.hr--inner45{
				opacity: 1;
				margin:15px auto 25px auto;
			}
		}
	}
}
.home{
	.section__wrapper{
		.project__other__h2{
			color: #fff;
		}
	}
}


// responsive
@include break (1440){
	.project__other{
		&__li{ width:33.33%; }
	}
}
@include break (980){
	.project__other{
		&__li{ width:50%; }	
		&__desc{
			visibility: visible;
			opacity: 1;
		}
		&__h2,
		&__h4{
			opacity: 1;
		}
		.hr--inner45{
			opacity: 1;
			margin:15px auto 25px auto;
		}
	}
}
@include break (600){
	.project__other{
		&__li{ width:100%; }
	}
}