/* Avenir */
@font-face {
  font-family: 'avenir-book';
  src: url('/fonts/avenir/AvenirLTStd-Book.eot');
  src: url('/fonts/avenir/AvenirLTStd-Book.eot?#iefix') format('embedded-opentype'), 
       url('/fonts/avenir/AvenirLTStd-Book.woff') format('woff'), 
       url('/fonts/avenir/AvenirLTStd-Book.ttf') format('truetype'),
       url('webfont.svg#AvenirLTStd-Book') format('svg');
}

/* open sans */
@font-face {
  font-family: 'open-sans.light';
  src: url('/fonts/open-sans/open-sans.light.eot');
  src: url('/fonts/open-sans/open-sans.light.eot') format('embedded-opentype'), 
	   url('/fonts/open-sans/open-sans.light.woff2') format('woff2'), 
	   url('/fonts/open-sans/open-sans.light.woff') format('woff'), 
	   url('/fonts/open-sans/open-sans.light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open-sans.regular';
  src: url('/fonts/open-sans/open-sans.regular.eot');
  src: url('/fonts/open-sans/open-sans.regular.eot') format('embedded-opentype'), 
	   url('/fonts/open-sans/open-sans.regular.woff2') format('woff2'), 
	   url('/fonts/open-sans/open-sans.regular.woff') format('woff'), 
	   url('/fonts/open-sans/open-sans.regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open-sans.semibold';
  src: url('/fonts/open-sans/open-sans.semibold.eot');
  src: url('/fonts/open-sans/open-sans.semibold.eot') format('embedded-opentype'), 
	   url('/fonts/open-sans/open-sans.semibold.woff2') format('woff2'), 
	   url('/fonts/open-sans/open-sans.semibold.woff') format('woff'), 
	   url('/fonts/open-sans/open-sans.semibold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open-sans.bold';
  src: url('/fonts/open-sans/open-sans.bold.eot');
  src: url('/fonts/open-sans/open-sans.bold.eot') format('embedded-opentype'), 
	   url('/fonts/open-sans/open-sans.bold.woff2') format('woff2'), 
	   url('/fonts/open-sans/open-sans.bold.woff') format('woff'), 
	   url('/fonts/open-sans/open-sans.bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open-sans.extrabold';
  src: url('/fonts/open-sans/open-sans.extrabold.eot');
  src: url('/fonts/open-sans/open-sans.extrabold.eot') format('embedded-opentype'), 
	   url('/fonts/open-sans/open-sans.extrabold.woff2') format('woff2'), 
	   url('/fonts/open-sans/open-sans.extrabold.woff') format('woff'), 
	   url('/fonts/open-sans/open-sans.extrabold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* montserrat */
@font-face {
    font-family: 'montserrat.ultralight';
    src: url('/fonts/montserrat/montserrat-ultralight.eot');
    src: url('/fonts/montserrat/montserrat-ultralight.eot?#iefix') format('embedded-opentype'),
         url('/fonts/montserrat/montserrat-ultralight.woff2') format('woff2'),
         url('/fonts/montserrat/montserrat-ultralight.woff') format('woff'),
         url('/fonts/montserrat/montserrat-ultralight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserrat.light';
    src: url('/fonts/montserrat/montserrat-light.eot');
    src: url('/fonts/montserrat/montserrat-light.eot?#iefix') format('embedded-opentype'),
         url('/fonts/montserrat/montserrat-light.woff2') format('woff2'),
         url('/fonts/montserrat/montserrat-light.woff') format('woff'),
         url('/fonts/montserrat/montserrat-light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserrat.regular';
    src: url('/fonts/montserrat/montserrat-regular.eot');
    src: url('/fonts/montserrat/montserrat-regular.eot?#iefix') format('embedded-opentype'),
         url('/fonts/montserrat/montserrat-regular.woff2') format('woff2'),
         url('/fonts/montserrat/montserrat-regular.woff') format('woff'),
         url('/fonts/montserrat/montserrat-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserrat.semibold';
    src: url('/fonts/montserrat/montserrat-semibold.eot');
    src: url('/fonts/montserrat/montserrat-semibold.eot?#iefix') format('embedded-opentype'),
         url('/fonts/montserrat/montserrat-semibold.woff2') format('woff2'),
         url('/fonts/montserrat/montserrat-semibold.woff') format('woff'),
         url('/fonts/montserrat/montserrat-semibold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Zona Pro Bold */ 
@font-face {
    font-family: 'zonapro.bold';
    src: url('/fonts/zona-pro/ZonaPro-Bold.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

/* Zona Pro Thin */ 
@font-face {
    font-family: 'zonapro.thin';
    src: url('/fonts/zona-pro/ZonaPro-Thin.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}


/* FLOWERSTUDIO */
/* Proxima Nova */
@font-face {
  font-family:'proximanova.bold';
  src: url('/fonts/proximanova/proximanova.bold.eot');
  src: url('/fonts/proximanova/proximanova.bold.eot?#iefix') format('embedded-opentype'),
       url('/fonts/proximanova/proximanova.bold.woff2') format('woff2'),
       url('/fonts/proximanova/proximanova.bold.woff') format('woff'),
       url('/fonts/proximanova/proximanova.bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family:'proximanova.light';
  src: url('/fonts/proximanova/proximanova.light.eot');
  src: url('/fonts/proximanova/proximanova.light.eot?#iefix') format('embedded-opentype'),
       url('/fonts/proximanova/proximanova.light.woff2') format('woff2'),
       url('/fonts/proximanova/proximanova.light.woff') format('woff'),
       url('/fonts/proximanova/proximanova.light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family:'proximanova.regular';
  src: url('/fonts/proximanova/proximanova.regular.eot');
  src: url('/fonts/proximanova/proximanova.regular.eot?#iefix') format('embedded-opentype'),
       url('/fonts/proximanova/proximanova.regular.woff2') format('woff2'),
       url('/fonts/proximanova/proximanova.regular.woff') format('woff'),
       url('/fonts/proximanova/proximanova.regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family:'proximanova.semibold';
  src: url('/fonts/proximanova/proximanova.semibold.eot');
  src: url('/fonts/proximanova/proximanova.semibold.eot?#iefix') format('embedded-opentype'),
       url('/fonts/proximanova/proximanova.semibold.woff2') format('woff2'),
       url('/fonts/proximanova/proximanova.semibold.woff') format('woff'),
       url('/fonts/proximanova/proximanova.semibold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}




/* fontawesome */
@font-face {
  font-family: 'FontAwesome';
  src: url('/fonts/fontawesome/fontawesome-webfont.eot?v=4.5.0');
  src: url('/fonts/fontawesome/fontawesome-webfont.eot?#iefix&v=4.5.0') format('embedded-opentype'), 
	   url('/fonts/fontawesome/fontawesome-webfont.woff2?v=4.5.0') format('woff2'), 
	   url('/fonts/fontawesome/fontawesome-webfont.woff?v=4.5.0') format('woff'), 
	   url('/fonts/fontawesome/fontawesome-webfont.ttf?v=4.5.0') format('truetype'), 
	   url('/fonts/fontawesome/fontawesome-webfont.svg?v=4.5.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Open sans */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,800,700,300italic,400italic,600italic,700italic,800italic);



