@keyframes bounce {
  0%, 20%, 50%, 80%, 100% { transform:translateY(0); }
  40% { transform:translateY(-10px); }
  60% { transform:translateY(-5px); }
}
@keyframes arrowBounceLeft {
  0%, 20%, 50%, 80%, 100% { left:0; }
  40% { left:-10px; }
  60% { left:-5px; }
}
@keyframes arrowBounceRight {
  0%, 20%, 50%, 80%, 100% { right:0; }
  40% { right:-10px; }
  60% { right:-5px; }
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(1.7);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes flipInY {
  from {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -10deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  100%{
    transform: perspective(400px) rotate3d(0, 1, 0, 0deg);
    opacity: 1;
  }
  to {
    transform: perspective(400px);
  }
}

@keyframes hrscalelr {
  0% {
    opacity: 0;
    width:0;
    background:#fff;
  }
  100% {
    opacity: 1;
    width:100px;
    background:#fff; 
  }
}

@keyframes fadeInright {
  0% {
    opacity: 0;
    // transform: rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
  100% {
    opacity: 1;
    // transform: rotate(360deg);
    transform: translate(-80px, 0px) rotate(-360deg);
  }
}

@keyframes fromTop {
  0%{ top:-30px; opacity:0; visibility:hidden; }
  100%{ top:0px; opacity:1; visibility:visible; }
}

@keyframes turn-on{
  11%{
    -webkit-filter:contrast(0) brightness(0) ;
    filter:contrast(0) brightness(0);
    opacity:0;
  }

  100%{
    -webkit-filter:contrast(1) brightness(1) saturate(1);
    filter:contrast(1) brightness(1) saturate(1);
    opacity:1;
  }
}
@keyframes opc {
  0%   {opacity: 0;}
  25%  {opacity: 0;}
  50%  {opacity: 0;}
  99%  {opacity: 0;}
  100% {opacity: 1;}
}
@keyframes flicker{
  $steps:20;
  @for $i from 0 through $steps{
    #{percentage($i*(1/$steps))}{
      opacity:random();
    }
  }
}
@keyframes spin {
  from {
    transform: rotateX(-81deg);}
    to {
      transform: rotateX(0deg);}
    }
