.dg-container{
	width: 100%;
	height: auto;
	position: relative;
}
.dg-wrapper{
	width: 457px;
	height: 700px;
	margin: 0 auto;
	position: relative;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	-o-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-perspective: 1000px;
	-moz-perspective: 1000px;
	-o-perspective: 1000px;
	-ms-perspective: 1000px;
	perspective: 1000px;
}
.dg-wrapper a{
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	filter: grayscale(0);
	backface-visibility:hidden;
	box-shadow: 0px 30px 60px #D4D5D8;
	box-shadow: 0px 30px 60px rgba(0,0,0,0.25);
}
.dg-wrapper a.dg-center{
	filter: grayscale(0);
	opacity: 1 !important;
	
}
.dg-wrapper a.dg-transition{
	transition: all 0.5s ease-in-out;
}
.dg-wrapper a img{
	display: block;
	// padding: 41px 0px 0px 1px;
	transform:scale(1);
	transition: all 0.3s ease-in-out;
}
.dg-wrapper a.dg-center img{
	display: block;
	transform:scale(1);	
	box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.55);
}
.dg-wrapper a div{
    font-style: italic;
    text-align: center;
    line-height: 50px;
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.5);
    color: #83817D;
    letter-spacing: 0.5px;
    font-size: 12px;
    width: 100%;
    bottom: -55px;
    display: none;
    position: absolute;
}
.dg-wrapper a.dg-center div{
	display: block;
}
.dg-container nav{
	position: absolute;
	left: 0;
	right: 0;
	z-index: 100;
	bottom: 0;
	top: 0;
	margin: auto; 
}
.dg-container nav span{
	// text-indent: -9000px;
	// float: left;
	cursor:pointer;
	width: 25px;
	height: 25px;
	opacity: 0.8;
}
.dg-container nav span:hover{
	opacity: 1;
}
.dg-container nav {
	.dg-next{
		position: absolute;
		top: 50%;
		margin-top: -22px;
		z-index: 10;
		right: 10px;
		left: auto;
	}
	.dg-prev{
		position: absolute;
		top: 50%;
		margin-top: -22px;
		z-index: 10;
		left: 10px;
		right: auto;
	}
}

@media (max-width: 768px) {
	.dg-wrapper a{
		box-shadow: none;
	}
}