form {	
}
input[type=text], input[type=number], input[type=email], select, textarea {	
	outline: none;
	font-size:15px;
	// border-radius: 6px;
	background:transparent;
	border:1px solid #747474;
	box-shadow:none;
	padding: 10px 12px;
	margin-bottom:5px;
	// height:36px;		
	color: #000000;
	font-family: 'opensans.regular', sans-serif;
	// &:hover{
	// 	border:1px solid #d719d7;
	// 	-moz-box-shadow:    0 0 8px rgba(255,91,255,0.2);
	//    -webkit-box-shadow: 0 0 8px rgba(255,91,255,0.2);
	//    box-shadow:         0 0 8px rgba(255,91,255,0.2);
	//    outline:none;
	// }
	// &:focus,&:active{
	// 	border:1px solid #d719d7;
	// 	-moz-box-shadow:    0 0 8px rgba(255,91,255,0.2);
	//    -webkit-box-shadow: 0 0 8px rgba(255,91,255,0.2);
	//    box-shadow:         0 0 8px rgba(255,91,255,0.2);
	//    outline:none;
	// }
	&::-webkit-input-placeholder {
		color: #BBBBBB;
	}
	&::-moz-placeholder {
		color: #BBBBBB;
	}
	&:-moz-placeholder {
		color: #BBBBBB;
	}
	&-ms-input-placeholder {
		color: #BBBBBB;
	}
}
input[type=file]{
	border-radius: 0;
	background:#140d13;
	border:1px solid #392429;
	box-shadow:none;
	padding: 4px;		
	color: #fff;
	font-family: 'Lato', sans-serif;
	font-size: 15px;
	// width: 300px;
	outline:none;
	&::-webkit-file-upload-button{
		font-family: 'Lato', sans-serif;
		color:#fff;
		height:35px;
		border:2px solid #6f1e8a;
		background:#140d13;
		padding:5px 8px;
	}
	&:before{
		display:none;
	}
}
textarea{
	padding: 6px 12px !important;
	resize: none;
}
input[type=radio], input[type=checkbox]{
	&:before{
		border:none !important;
	}
}		
select {
	cursor:pointer;
	height:35px;
	padding:0 25px;
	background-image: none;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
	background:url(/images/game/asta/arrow-down.png) no-repeat 95% 50% #140d13;
	&:focus,&:active{
		border:1px solid #d719d7;
		-moz-box-shadow:none;
	   -webkit-box-shadow:none;
	   box-shadow:  none;
	   outline:none;
	}
	option{
		height:35px;
		margin:0px;
		padding:12px;
		box-shadow:none;
	    background: #140d13;
	    color:#666;
	    outline:none;
	    &:hover{
	    	background:#fff;
	    }
	}
}

.form__wrapper{
	padding-top:12px;
	// width:400px;
	margin:0 auto;
	display:flex;
	label{
		width: 110px;
		margin-right:5px;		
	}
	input,textarea{
		width:100%;
	}
}

//alert
.info__success,
.info__warning{
	color:#b1adc4 !important;
}
.info__error,
.info__error2{
	color:#d75552 !important;
}
.error__state{
	input[type=text], input[type=number], input[type=email], select, textarea{
		border: 1px solid #F1494C;
	}
}