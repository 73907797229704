$red:#ff0000;
$grey:#dfddde;
$brown:#480001;
$brown2:#270101;
$darkgrey:#464646;

.grey{ color:$grey; }
.brown{ color:$brown; }
.brown2{ color:$brown2; }
.darkgrey{ color:$darkgrey; }

.grayscale{
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+ */
  // @include blurbg (grayscale(1));
  // @include noflicker(); /* Fix for transition flickering */
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
  filter: grayscale(1);
  &:hover{
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
    // @include blurbg (grayscale(0));
    -webkit-filter: grayscale(0);
    -moz-filter: grayscale(0);
    -o-filter: grayscale(0);
    -ms-filter: grayscale(0);
    filter: grayscale(0);
  }
}