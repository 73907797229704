.footer{
	background-color: #252525;
	padding:45px 0 40px 0;
	.copyright-mobile {
		font-family: "montserrat.regular", sans-serif;
		font-size: 14px;
		letter-spacing: 1.19px;
		line-height: 36px;
		display: none;
		color: #9A9A9A;
	}
	.footer__quick__menu {
		a {
			font-family: "opensans.regular", sans-serif;
			font-size: 12.04px;
			color: #9A9A9A;
			letter-spacing: 0.33px;
			line-height: 19.56px;
		}
	}
	.icon-footer {
		margin-top: 10px;
		margin-right: 10px;
	}
	.footer__address-mobile {
		display: none;
	}
	.part-midplaza-mobile {
		display: none;
	}
	&__left{
		width: 30%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__right{
		width: 70%;
	}
	&__wrapper{
		width: 1200px;
		margin: 0px auto;
		display: flex;
		justify-content: center;
		align-items: center;
		.footer__img__mobile {
			display: none;
		}
	}
	&__link{
		display: flex;
		padding:0;
		&__li{
			width: 33.3%;
			margin-top: 10px;
			.copytext{
				color:#959595;
				letter-spacing: 0.8px;
			}
			.copyqeon{
				font-size: 10.53px;
				letter-spacing: 0.9px;
				line-height: 27.09px;
				color: #9A9A9A;
				font-family: "montserrat.regular", sans-serif;
			}
			p{
				font-family: 'open-sans.regular';
				font-size: 12.04px;
				line-height: 19.56px;
				letter-spacing: 0.33px;
				margin: 0;
			}
			a{
				font-family: 'open-sans.regular';
				font-size: 13px;
				line-height: 21px;
				letter-spacing: 0.3px;
				margin: 0;
				&:hover{
					text-decoration: underline;
				}
			}
		}
		&__a{
			position: relative;
			display: block;
			color:#fffefe;
			.ico-place{
				transition: 0.3s ease;
			}
			&:hover{
				color: #F1494c;
				text-decoration: none !important;
				.ico-place{
					background: url(/images/ico-place-hover.svg) no-repeat;
					background-size: 12px;
				}
			}
		}		
	}
	.container__wrapper{
		margin:0 auto;
	}
	.footer__img{
		width: 180px;
	}
	.footer__img__sm{
		background: url(/images/logo-footer.png) no-repeat;
		background-position: bottom;
		background-size: 180px;
		width: 180px;
		height: 50px;
		display:none;
	}
	.footer__findus{
		display: flex;
		align-items: center;
		font-size: 10px;
		letter-spacing: 0.5px;
		margin-top: 1px;
	}
	.footer__left{
		.copyqeon{display: none;}
	}
}

/* responsive */
@include break(1200){
	.footer__wrapper{
		width: 100%;
	}
}
@include break(768){
	.footer__wrapper{
		flex-direction: column;
		.footer__link{
			flex-wrap: wrap;
		}
		.footer__right {
			width: 100%;
			display: block;
			padding: 0 20px;
		}
		.footer__left {
			display: block;
			padding: 0 20px;
			width: 100%;
			text-align: left;
			.copyqeon {
				display: none;
			}
			.footer__img {
				display: none;
			}
			.footer__img__mobile {
				display: block;
				margin-bottom: 25px;
				width: 85px;
			}
		}
	}
	.footer {
		padding: 34px 0 22px 0;
		.copyright-mobile {
			display: block;
		}
		.icon-footer {
			display: none;
		}
		.footer__address {
			display: none;
		}
		.footer__address-mobile {
			display: block;
		}
		.footer__findus {
			display: none;
		}
		.part-midplaza-mobile {
			display: block;
			margin-top: 23px;
			margin-bottom: 9px;
			width: 133px;
		}
	}
	.footer__link__li {
		width: 100%;
		margin-top: 0;
	}
}
@include break(768){
	.footer__wrapper{
		flex-direction: column;
		.footer__link{
			flex-wrap: wrap;
			.footer__link__li {
				margin-top: 0;
				p {
					font-size: 14px;
					letter-spacing: 0.34px;
					line-height: 22px;
				}
				.footer__quick__menu {
					font-size: 14px;
					font-family: "opensans.regular", sans-serif;
					letter-spacing: 0.38px;
					line-height: 26px;
					color: #9A9A9A;
					margin-top: 13px;
				}
				.copyqeon {
					color: #9A9A9A;
					letter-spacing: 1.19px;
					line-height: 36px;
				}
			}
		}
	}
}

@include break(600){
	.footer__wrapper{
		.footer__link{
			&.address{
				.footer__link__li{
					&:first-child{
						width: 100%;
					}
					&:nth-child(2){
						width: 100%;
					}
				}
			}
		}
	}
}

@include break (480){
	.footer{
		&__link{
			&__a{
				&:first-child{
					&:before{
						display: none;
					}
				}
			}
		}		
	}
}