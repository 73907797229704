
body {
    font-family: 'montserrat.regular', sans-serif;
    color: white;
}
h1 {
    font-size: 60px;
    font-weight: normal;
    letter-spacing: 4px;
    margin:0;
    padding:0;
}
h2 {
    font-family: 'montserrat.regular', sans-serif;
    font-size: 32px;
    font-weight: normal;
    letter-spacing: 0;
    margin:0;
    padding:0;
}
h3 {
    font-family: 'montserrat.semibold', sans-serif;
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 2px;
    margin: 12px 0;
    padding: 0;
}
h4 {
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 1.5px;
    margin:0;
    padding:0;
}
h5 {
    font-size: 16px;
    font-weight: bold;
    margin:0;
    padding:0;
}
p {
    font-family: 'open-sans.light';
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.8px;
    line-height:26px;
    &.s14{font-size:14px;line-height:25px;}
}    
a {
    cursor:pointer;
    font-size: 14px;
    text-decoration: none;
    color: white;
    // &:hover{ color:#bc6ed7; }      
}
small {
    font-size: 13px;
    color:white;
    letter-spacing: 0.8px;
    .small2{
        font-size:12px;
    }
}
label{
    font-size: 14px;
    color:#000;
}
hr{
    border:none;
    border-top:1px solid #3a2d3c;
    margin: 10px 0;    
    &.hr--inner45{
        width:45px;
        border:0;
        border-top:2px solid $red;
        margin: 15px 0 25px 0;
    }
    &.hr--inner70{
        width:70px;
        border:0;
        border-top:3px solid $red;
        margin: 15px 0;
    }
    &.hr--inner100{
        width:100px;
        border:0;
        border-top:6px solid $red;
        margin: 15px 0;
    }
}
ul{
    list-style:none;
    padding:1em 0;
    margin:0;     
    li{
        a{ 
            text-decoration: none;
            img{
                float:left;
            }
        }
    }
    &.disc{
        list-style-type: disc;
        padding: 0 0 0 1em;
        li{
            line-height: 130%;
            margin: 5px 0;
        }
    }
}
nav{
    display: flex;
    ul{
        margin:0 auto;
        padding:0;
        li{float:left;}
    }
}

// responsive
@include break (768){
    h1 {
        font-size: 40px;
    }
    h2 {
        font-size: 24px;
    }
    h3 {
        font-size: 18px;
    }
    p, a, label, li{
        font-size: 14px;
    }
}
@include break (480){
    h1 {
        font-size: 20px;
    }
    h2 {
        font-size: 18px;
    }
    h3 {
        font-size: 16px;
    }
    p, a, label, li{
        font-size: 13px;
        line-height: 22px;
    }
}


