.ico-place{
	background: url(/images/ico-place.svg) no-repeat;
	display: inline-block;
	width: 12px;
	height: 19px;
	margin: 0 5px 0 0;
	background-size: 12px;
}

.ico-arrow {
	width: 17px;
	height: 17px;
	display: inline-block;
	border-top: 1px solid;
	border-left: 1px solid;
	position: absolute;
	top: 0;
	&.prev{
		transform: rotate(-45deg);
		left: 0;
		color: #3C3C3B;
		color: rgba(60,60,59, 0.7);
	}
	&.next{
		transform: rotate(135deg);
		right: 0;
		color: #3C3C3B;
		color: rgba(60,60,59, 0.4);
	}
}

.project__pagination {
	.ico-arrow {
		&.prev, &.next {
			color: #fff;
		}
	}
}
