.montserrat {
	&.light {
		font-family: "montserrat.light", sans-serif;
	}
	&.regular {
		font-family: "montserrat.regular", sans-serif;
	}
}

.opensans {
	&.light {
		font-family: "open-sans.light", sans-serif;
	}
	&.bold {
		font-family: "open-sans.bold", sans-serif;
	}
	&.regular {
		font-family: "opensans.regular", sans-serif;
	}
}

.avenir {
	&.book {
		font-family: "avenir.book", sans-serif;
	}
}