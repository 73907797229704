.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
	background-image: none;
	.ico-arrow{
		width: 20px;
		height: 20px;
		top: 0;
		bottom: 0;
		margin: auto;
	}
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
	background-image: none;
	.ico-arrow{
		width: 20px;
		height: 20px;
		top: 0;
		bottom: 0;
		margin: auto;
	}
}

.swiper-button-next{
	&:hover{
		opacity: 0.9;
	}
}

.swiper-button-prev{
	&:hover{
		opacity: 0.9;
	}
}

.swiper-pagination-bullet {
    width: 5px;
    height: 5px;
    background: transparent;
    border: 1px solid #000;
}
.swiper-pagination-bullet-active {
    opacity: 1;
    background: #979797;
    border: 1px solid #979797;
}