.project__pagination{
	.project__pagination__prev{
		text-align: left;
		display: inline-block;
		padding: 0 0 0 25px;
		&:hover{
			.ico-arrow{
				animation: arrowBounceLeft 1.8s infinite;
			}
		}
	}
	.project__pagination__all{
		text-align: center;
		display: inline-block;
	}
	.project__pagination__next{
		text-align: right;
		display: inline-block;
		padding: 0 25px 0 0;
		&:hover{
			.ico-arrow{
				animation: arrowBounceRight 1.8s infinite;
			}
		}
	}
	a{
		color: #FFF;
		text-transform: uppercase;
		transition: all .3s cubic-bezier(.645,.045,.355,1) 0s;
		&:hover{
			color: #fff;
		}
	}
	.project__pagination__next, .project__pagination__prev {
		span {
			color: #747474;
			font-size: 13.54px;
			letter-spacing: 1.16px;
		}
	}
}

// STYLE BORDER NEXT PREV PROJECT
#section-project-other{
	border-bottom: 1px solid #3C3C3B;
	.section__wrapper{
		padding: 40px 0;
	}
}

@include break (1200){
	.project__pagination {
		width: 90%;
		margin: 0px auto;
	}
}

@include break (768){
	.project__pagination {
		width: 100%;
		padding: 0 20px;
		.column33{
			width: 33.33%;
		}
		span{
			font-size: 12px;
		}
		.project__pagination__next{
			padding: 0 10px 0 0;
			span{
				display: none;
			}
			i{
				position: relative;
			}
		}
		.project__pagination__prev{
			padding: 0 0 0 10px;
			span{
				display: none;
			}
			i{
				position: relative;
			}
		}
		.project__pagination__prev, .project__pagination__next {
			display: block;
			&:hover {
				.ico-arrow {
					animation: none;
				}
			}
		}
	}
	#section-project-other {
		.section__wrapper {
			padding: 27px 0;
		}
	}
}