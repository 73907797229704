.ico--nav{
    display: block;
    position: relative;
    height:2px;
    width:24px;
    background:#252525;
    cursor:pointer;    
    transition:0.4s all;
    @extend .noflicker;
    &:before{
        content:"";
        position: absolute;
        top:-7px; left:0;
        height:2px;
        width:24px;
        background:#252525;
        transition:0.4s all;
    }
    &:after{
        @extend.ico--nav:before;
        background-color: #252525; 
        top:initial;
        bottom:-7px;
    }    
    &__wrapper{
        position: relative;
        z-index: 50;
        cursor:pointer;
        display: none;
        align-items: center;
        width:24px;
        height:24px;
        // &:hover{
        //     .ico--nav{
        //         &:before{ top:-8px; }
        //         &:after{ top:initial; bottom:-8px; }
        //         &.animate{
        //             &:before{ top:-1px; transform:rotate(45deg); }
        //             &:after{ top:initial; bottom:-1px; transform:rotate(-45deg); }
        //         }
        //     }            
        // }
    }
    // &.animate{
    //     height:0px;
    //     &:before{ top:-1px; transform:rotate(45deg); }
    //     &:after{ top:initial; bottom:-1px; transform:rotate(-45deg); }    
    // }
}

.navmenu{
    &__ul{
      display: flex;
      justify-content: flex-end;
      width: 100%;      
      list-style:none;
      margin:0;
      padding:0;
    }
    &__li{
      position: relative;
      // overflow: hidden;
      height:31px;
      margin:0 5px;
      color:white;
      display: flex;
      justify-content: center;
      align-items:center;
      &.active{ a{ color: darken(#CECECE, 5%); } }
      a{ 
        // padding: 6px 25px;
        // font-size:14px; 
        color: #000;
        margin: 6px 18px;
        padding: 5px 0;
        font-size: 11px;
        letter-spacing:1.5px; 
        &.active{ color:#fe3232; }
      } 
    }
}

.navmenu-m{
    @extend .noflicker;
    display:flex;    
    align-items:center;
    justify-content:center; 
    position: fixed;
    width:100vw;
    height:100vh;
    z-index: 49;
    top:0; left:0;    
    background: linear-gradient(rgba(0, 0, 0, 0.95) 50%, rgba(0, 0, 0, 0.96) 100%);
    opacity: 0;
    visibility: hidden;
    // transition:0.4s all;    
    &.active{
        opacity: 1;
        visibility: visible;
        overflow: hidden;
        .navmenu-m__li{
            margin-top:0px;
            opacity: 1;
            visibility: visible;
            // &:nth-child(1){ transition:0.4s all 0.1s; }
            // &:nth-child(2){ transition:0.4s all 0.2s; }
            // &:nth-child(3){ transition:0.4s all 0.3s; }
            // &:nth-child(4){ transition:0.4s all 0.4s; }
            // &:nth-child(5){ transition:0.4s all 0.5s; }
        }
    }
    &__ul{
        display: flex;
        flex-flow:column wrap;
        align-items:center;
        justify-content:center;
        &.menu--juno .menu__item::before {
            top: 5px;
        }
        &.menu--juno .menu__item::after {
            bottom: 5px;
        }    
    }
    &__li{
        position: relative;
        text-align: center;
        margin-top:25px;
        opacity: 0;
        visibility: hidden;
        // transition:0.4s all;
    }
    &__a{
        position: relative;
        display: block;
        font-family: 'montserrat.regular', sans-serif;
        font-size: 18px;
        letter-spacing: 2px;
        padding: 12px 70px;
        margin: 5px 0;       
    }
}


.menu--juno{
  .menu__item{
    .menu__link{
      position: relative;
      &:hover{
        &:before{
          visibility: visible;
          // transform: scaleX(1);
          width: 100%;
        }
      }
      &:before{
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -2px;
        left: 0;
        background-color: #3C3C3B;
        visibility: hidden;
        width:0;
        // transform: scaleX(0);
        transition: all 0.3s ease-in-out;
      }
    }
  }
}


/* Juno menu style */
// .menu--juno .menu__item::before,
// .menu--juno .menu__item::after,
// .menu--juno .menu__link::before,
// .menu--juno .menu__link::after {
//   content: '';
//   position: absolute;
//   pointer-events: none;
//   opacity: 0;
//   background: #fe3232;
//   -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
//   transition: transform 0.3s, opacity 0.3s;
//   -webkit-transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
//   transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
// }

// /* top and bottom line */
// .menu--juno .menu__item::before,
// .menu--juno .menu__item::after {
//   left: 0;
//   width: 100%;
//   height: 1px;
// }

// .menu--juno .menu__item::before {
//   top: 0;
//   -webkit-transform: translate3d(0, 6px, 0);
//   transform: translate3d(0, 6px, 0);
// }

// .menu--juno .menu__item::after {
//   bottom: 0;
//   -webkit-transform: translate3d(0, -6px, 0);
//   transform: translate3d(0, -6px, 0);
// }

// /* left and right line */
// .menu--juno .menu__link::before,
// .menu--juno .menu__link::after {
//   top: 0;
//   width: 1px;
//   height: 100%;
// }

// .menu--juno .menu__link::before {
//   left: 0;
//   -webkit-transform: translate3d(6px, 0, 0);
//   transform: translate3d(6px, 0, 0);
// }

// .menu--juno .menu__link::after {
//   right: 0;
//   -webkit-transform: translate3d(-6px, 0, 0);
//   transform: translate3d(-6px, 0, 0);
// }

// .menu--juno .hover--current::before,
// .menu--juno .hover--current::after,
// .menu--juno .hover--current .menu__link::before,
// .menu--juno .hover--current .menu__link::after {
//   opacity: 1;
//   -webkit-transform: translate3d(0, 0, 0);
//   transform: translate3d(0, 0, 0);
// }

/* menu section */
.menu-page{
    position: fixed;
    top:0; bottom:0; right:40px;
    display: flex;
    align-items: center;
    height:312px;
    margin:auto;           
    z-index:40;
    opacity:1;
    visibility: visible;
    // transition:0.6s all;
    &.hide{
        opacity:0;
        visibility: hidden;
    }
    &__ul{
        list-style: none;
        // margin-right: -50px;
    }
    &__li{
        position:relative;
        margin: 10px 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        &.active{
            .menu-page--tooltip{
                visibility: visible;
                opacity: 1;
            }
            .circle{
                background:rgba(175,175,175,1);
            }
        }
        .circle{
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: rgba(175, 175, 175, 0.5);
            z-index: 10;
            &:hover{ background:rgba(175,175,175,1); }
            &:hover ~ .menu-page--tooltip{
                visibility: visible;
                opacity: 1;
            }
        }           
    }
    &--tooltip{         
        visibility: hidden;
        opacity: 0;
        font-size: 13px;
        color: #afafaf;
        text-align: right;
        display: inline-table;
        padding: 0 18px 0 0;
        transition: 0.3s all;
    }    
}

/* responsive */
@include break (980){
    .menu-page{ display:none; }
}

@include break (768){
    .navmenu{
        &__ul{ display: none; }
    }
    .ico--nav{
        &__wrapper{ display: flex; }
    }
}