////////// bxslider custom //////////////
.bx-wrapper{
  box-shadow:none !important;
  border:0 !important;
}
.pager__container{
    width:100%;
    text-align:center;
    display: block;
    position: absolute !important;
    bottom: 0px !important;
    margin:0 auto !important;
    .one-pager-for-all{
        list-style-type:none;
        padding:0;
        margin:0;
        display: inline-block;
        li{
            float:left;
            margin-right:15px;
            a{
                float:left;
                margin-right:15px;
                cursor:pointer;
            }
        }
    }
    .bx-pager-item a{
      cursor: pointer;
    }
}


.frame{
  &__slider{ 
    visibility: hidden;
  }
  &--tablet__slider{
    width:332px;
    height:251px;
    margin: 15px 0 0 38px;
    .bx-wrapper{
      .bx-viewport{
        
      }
    }
  }
  &--pc__slider{
    width:592px;
    height:355px;
    margin: 25px 0 0 26px;
    .bx-wrapper{
      .bx-viewport{
        
      }
    }
  }
  &--mobile__slider{
    width:133px;
    height:235px;
    margin: 38px 0 0 14px;
    .bx-wrapper{
      .bx-viewport{
        
      }
    }
  }
}


.gallery__thumb{
  width:100%;
  .bx-wrapper{
    background: transparent !important;
    margin:0 !important;
    .bx-viewport{
      box-shadow:none !important;
      border: none !important;
        .slider{
          margin: 0 auto;
        }
    }    
  }
  .bx-controls{
      .bx-pager{        
        .bx-pager-item{
          a{}            
        }
      }
      .bx-controls-direction{
        .bx-prev{
          left:100px !important;
          font-size:24px !important;
        }
        .bx-next{
          right:100px !important;
          font-size:24px !important;
        }
      }
  }
}
