.spacer {
	text-align: center;
	min-height: 100px;
	&#descdevice__content__wrapper{
		position:absolute;
		top:45%;
	}
	&#gallery__hr{
		position:absolute;
		top:45%;
	}
	&#gallery__thumb__ulli{
		position:absolute;
		top:38%;
	}
	&#contact__hr{
		position:absolute;
		top:0;
	}
}