@mixin sprite($width, $height, $position, $margin) {
	background:url(/images/game/asta/mainSprite.png) $position no-repeat;
	display: inline-block;
	width: $width;
	height: $height;
	margin: $margin;
	// @include break(retina) {
	// 	background:url(/images/new/sprite@2x.png) $position no-repeat;
	// 	background-size:1390px 800px;
	// }
}

.ico-as-qash{@include sprite(56px, 24px, 0 -32px, 0 5px)}
.ico-as-qpon{@include sprite(58px, 24px, 0 -56px, 0 5px)}
.ico-as-qshop{@include sprite(64px, 27px, 0 -80px, 0 5px)}

.ico-frame-topleft{@include sprite(35px, 29px, -240px 0, 0); }
.ico-frame-topright{@include sprite(35px, 29px, -270px 0, 0); }
.ico-frame-bottomleft{@include sprite(35px, 29px, -270px 0, 0); transform:rotate(180deg);}
.ico-frame-bottomright{@include sprite(35px, 29px, -240px 0, 0); transform:rotate(180deg);}

.ico-framebone-topright{@include sprite(48px, 22px, -144px 0, 0); transform:rotate(180deg);}
.ico-framebone-topleft{@include sprite(48px, 22px, -192px 0, 0); transform:rotate(180deg);}
.ico-framebone-bottomright{@include sprite(48px, 22px, -192px 0, 0)}
.ico-framebone-bottomleft{@include sprite(48px, 22px, -144px 0, 0)}

.ico-navbarleft{@include sprite(78px, 122px, 0 -107px, 0);}
.ico-navbarright{@include sprite(78px, 122px, -82px -107px, 0);}

.ico-subnav-hover{@include sprite(122px, 33px, -64px -28px, 0)}

.ico-news-news{@include sprite(24px, 24px, -32px 0, 0 5px)}
.ico-news-technical{@include sprite(24px, 24px, -56px 0, 0 5px)}
.ico-news-event{@include sprite(24px, 24px, -80px 0, 0 5px)}
.ico-news-update{@include sprite(24px, 24px, -104px 0, 0 5px)}
.ico-news-flag{@include sprite(16px, 16px, -128px 0, 0 5px)}
.ico-news-blank{width:16px; height:16px;}

.ico-news-all-big{@include sprite(48px, 40px, -184px -32px, 0)}
.ico-news-news-big{@include sprite(48px, 40px, -232px -32px, 0)}
.ico-news-technical-big{@include sprite(48px, 40px, -280px -32px, 0)}
.ico-news-events-big{@include sprite(48px, 40px, -328px -32px, 0)}
.ico-news-updates-big{@include sprite(48px, 40px, -376px -32px, 0)}

.ico-mos{ position:absolute; top: -33px; right: 0; left: 0; margin: 0 auto !important; }
.ico-mos-mail{@include sprite(54px, 54px, -162px -180px, 0)}
.ico-mos-letter{@include sprite(54px, 54px, -216px -180px, 0)}
.ico-mos-head{@include sprite(54px, 54px, -270px -180px, 0)}

.ico-remove-red{@include sprite(20px, 20px, -306px 0, 0)}
.ico-remove-grey{@include sprite(20px, 20px, -326px 0, 0)}

.ico-bg-circle{@include sprite(70px, 70px, -191px -100px, 0)}

