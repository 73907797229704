a,input[type='submit'],button{ 
    position:relative;
    display: inline-block;
	border: none; outline: none; 
	text-decoration:none;
	transition:0.3s ease;
    // font-family: 'Lato', sans-serif;	
    &.btn--main{        
        font-size: 14px;
        // font-weight:bold;
        letter-spacing: 1.5px;
		padding: 9px 27px;
        color: #fff;
        background: #252525;
        // background:#eeeded;
        border: 1px solid #252525;
        border-radius: 30px;
        &:hover{
            background: transparent;
            border: 1px solid #252525;
            color:#252525;
        }
    }
    &.btn--box{
        padding:8px 16px;
        border:1px solid #fff;
        z-index: 1;
        &:before{
            content:"";
            position: absolute;
            top:0; left:0;
            width:0; height:100%;
            background:#fe0000;
            transition:0.3s ease;
            z-index: -1;            
        }
        &:hover{
            border:1px solid #fe0000;
            &:before{
                width:100%;
            }
        }
    }
}