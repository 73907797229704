.header{
  position:absolute;
  z-index: 1000;
  top:0;  
  width:100%;
  background: #fff;
  @extend .noflicker;
  &__wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* width:1200px; */
    width: 1024px;
    max-width: 100%;
    height: 80px;
    margin:0 auto;
  }
  &__left{
    // padding-left: 60px;
    height:60px;
    display: flex;
    align-items: center;
    .svg--icon-qdh{      
      svg{
        margin-top: 6px;
        width:90px;
        // height:28px;
        g{
          path, 
          rect{
            // fill:#EC1C24;
          }
        }
      }
    }
  }
  &__right{
    // padding-right: 30px;
  }
  &.sticky{
    position:fixed;
    // background:#700000;
    animation:fromTop 0.4s cubic-bezier(0.01, 1.07, 1, 1) forwards;
    background: #fff;
    @extend .noflicker;
    .header__left{
      .svg-qdh-icon{      
        svg{
          width:28px;
          height:28px;
          g{
            path, 
            rect{
              fill:#ffffff;
            }
          }
        }
      }
    }
  }
  &.header--alpha {
    background-color: rgba(255, 255, 255, 0.8) !important;
  }
}

/* responsive */
@include break (1360){
  .header{
    &__left{
      height:40px;
    }
  }
}
@include break (1200){
  .header{
    &__wrapper{
      width:90%;
      margin: 0 auto;
    }
  }
}
@include break (980){
    .header{
      // position:fixed;
      // background:rgba(0,0,0, 0.8);
      // background:#700000;
      &__wrapper{ height:60px; }
      /* &__left{ padding-left:10px; }
      &__right{ padding-right:10px; } */
    }
}
@include break (768){
    .header{
      position:fixed;
      background: #fff;
      .svg--icon-qdh{
        .color626366{fill: #626366 !important;}
      }
    }
    .header__wrapper {
      width: 100%;
      padding: 0 20px;
    }
}